import React, { useState, useEffect, useRef } from 'react'
import Requests from './Requests';
import ApprovePending from '../../BDO/DeathCertificate/ApprovePending';
import axios from "axios";
import { APIURL } from "../../../Global";
function DeathReport() {
    const [selected, setSelected] = useState("requests");
    const [refresh, setRefresh] = useState(false);
    const [search, setSearch] = useState("");
    const [patient, setPatient] = useState([]);
    const [pending, setPending] = useState([]);
    const [request, setRequest] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sortData = (data) => {
        // Sort the data into appropriate categories
        const sortedData = data.reduce((acc, item) => {
            if (item.status === "pending") {
                acc.pending.push(item);
            } else if (item.status === "approved") {
                acc.patient.push(item);
            }
            return acc;
        }, {
            pending: [],
            patient: []
        });
    
        // Update the state with the sorted data
        setPending(sortedData.pending);
        setPatient(sortedData.patient);
    
        // After sorting, decide which tab to select based on the available data
        if (search !== '') {
            // If the search term is not empty, check the available data
            if (sortedData.pending.length > 0) {
                setSelected("requests");
            } else if (sortedData.patient.length > 0) {
                setSelected("reports");
            }
        }
    };
    

    useEffect(() => {
        const token = sessionStorage.getItem("usertoken")?.replace(/["]+/g, "");
        if (!token) return;

        axios.get(`${APIURL}/api/v1/service-provider/patient-death/`, {
            headers: { Authorization: `Token ${token}` },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setRequest(res.data.message); // Store the fetched data
                    if (search === '') {
                        sortData(res.data.message); // Sort data if no search term
                    } else {
                        handleSearchChange(search, res.data.message); // Apply filter when search term is present
                    }
                }
            })
            .catch((err) => console.error(err));
    }, [refresh]);

    const handleSearchChange = (val, data) => {
        setSearch(val);
        if (val === '') {
            sortData(request); // Reset to the original request data if no search
        } else {
            const filteredData = data
                .filter(({ patient_name, patient }) =>
                    patient_name.toLowerCase().includes(val.toLowerCase()) ||
                    patient.toString().toLowerCase().includes(val.toLowerCase())
                );
            sortData(filteredData); // Filter and then sort the data
        }
    };

    const renderContent = () => {
        switch (selected) {           
            case "requests":
                return <Requests person={pending} onRefresh={() => { setRefresh(!refresh) }} />;
            case "reports":
                return <ApprovePending person={patient} onRefresh={() => { setRefresh(!refresh) }} />;
            default:
                return null;
        }
    };

    return (
        <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>
            <div className='row'>
                <div className="col-lg-2 col-md-2">
                    <h5
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "20px",
                        }}
                    >
                        Death Applications
                    </h5>
                    <div className="leftdivdeathrepo">
                        <div className="searchboxdeathrepo">
                            <input
                                placeholder="Search Name or ID"
                                type="text"
                                value={search}
                                onChange={e => { e.preventDefault(); handleSearchChange(e.target.value, request) }}
                                style={{ marginLeft: '10px', marginTop: "3px", border: "0", outline: "none", width: "85%" }}
                            />
                        </div>
                        <h6
                            style={{
                                color: "#2D3748",
                                fontFamily: "Helvetica",
                                fontWeight: "700",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "12px",
                            }}
                        >
                        </h6>
                        {[ "requests", "reports"].map((type, index) => (
                            <button
                                key={index}
                                className={`deathreportbutton ${selected === type ? "active" : ""}`}
                                onClick={() => { setSelected(type) }}
                                style={{ backgroundColor: selected === type ? "#6D4482" : "#F8F8F8", color: selected === type ? "white" : "black" }}
                            >
                                <i className={`fa fa-${type === "form" ? "user" : "file"}`} aria-hidden="true" style={{ color: selected === type ? "white" : "#6D4482" }} />
                                &nbsp;&nbsp;{type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, " ")}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="col-lg-10 col-md-10">{renderContent()}</div>
            </div>
        </div>
    )
}

export default DeathReport;
