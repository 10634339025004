import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import moment from 'moment'
import { useAlert } from "react-alert";
import SimpleBar from 'simplebar-react';
import { Modal } from "react-bootstrap";
import './callforaction.css'
function CallFor(props) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [requests, setRequests] = useState([])
    const [selected, setSelected] = useState(props.from === "all" ? "all" : "pat")
    const [searchedRequests, setSearchedRequests] = useState([])
    const [refresh, setRefresh] = useState(false);
    const [refresh1, setRefresh1] = useState(false);
    const [id, setId] = useState(props.id && props.id)
    const [searchTerm, setSearchTerm] = useState("");
    const [pat_id, setPat_id] = useState('');
    const [mob, setMob] = useState('')
    const [access, setAccess] = useState(null)
    const role = sessionStorage.getItem("usertype");
    const handleSearchChange = (e) => {
        const search = e.target.value
        setSearchTerm(search)
        const filteredRequests = requests.filter(item =>
            item.patient_name.toLowerCase().includes(search.toLowerCase()) ||
            item.patient.toString().includes(search)
        );
        setSearchedRequests(filteredRequests)
    }

    useEffect(() => {
        const storedAccess = sessionStorage.getItem("access");

        if (storedAccess) {
            const parsedAccess = JSON.parse(storedAccess);
            setAccess(parsedAccess);
        } else {
            setAccess([]);
        }

    }, []);

    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        let url = selected === "pat" ? "/api/v1/doctor/call-for-action/?patient_id=" + id : "/api/v1/doctor/call-for-action/"
        axios
            .get(`${APIURL}${url}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {
                    setRequests(res.data.message)
                }
            })
            .catch((err) => { });

    }, [refresh]);

    const handleUpdate = (action_id, action, cfn, pat_id, pat_type, mob) => {
        const tokenStr = sessionStorage.getItem("usertoken");
        let data = {}

        if (action === "pin") {
            data = { is_pinned: true }
        } else if (action === "unpin") {
            data = { is_pinned: false }
        } else {
            data = { is_read: true, doc_attended: true }
        }

        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/doctor/call-for-action/?id=${action_id}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        if (action === "read") {
                            if (cfn !== "deceased" && cfn !== "admission-request" && cfn !== "discharge-request") {
                                if (access && access.some(item => ["0.1.4"].includes(item.node_access))) {
                                    navigate(`/patient-navigation/id/${pat_id}/mob/${mob}`, {
                                        state: { id: pat_id, mobile: mob },
                                    })
                                }
                                props.onClose()
                            }

                            else if (cfn === "admission-request") {
                                console.log(pat_id)
                                if (access && access.some(item => ["0.1.4"].includes(item.node_access))) {
                                    sessionStorage.setItem('navigatedToPatientSearch', 'true');
                                    navigate('/patientlist', {
                                        state: { pat_id: pat_id, pat_type: pat_type },
                                    })
                                }
                                props.onClose()
                            }
                            else if (cfn === "deceased") {
                                if ((access.some(item => item.node_access === "0.1.14") && (role === 'hospital_bdo' || role === 'hospital_pro'))) {
                                    sessionStorage.setItem('deathReportSearch', 'true');
                                    navigate('/death_report', {
                                        state: { pat_id: pat_id },
                                    })
                                }
                                props.onClose()
                            }
                            else if (cfn === "discharge-request") {
                                if (access && access.some(item => ["0.1.16"].includes(item.node_access))) {
                                    sessionStorage.setItem('dischargeSearch', 'true');
                                    navigate('/discharge', {
                                        state: { pat_id: pat_id, department_id: pat_type },
                                    })
                                }
                                props.onClose()
                            }

                            else {
                                setRefresh(!refresh)
                            }

                        }

                        else {
                            setRefresh(!refresh)
                        }

                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error occured");
                });
        }

    };

    return (
        <div>
            <Modal show={true} onHide={props.onClose} centered>
                <Modal.Header closeButton onHide={props.onClose}></Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        {selected === "all" || selected === 'pinned' ?
                            <>
                                <button onClick={() => setSelected("all")} className={`butn-noti ${selected === "all" ? 'butn-noti-focused' : ''}`}>
                                    All notifications
                                </button>
                                <button onClick={() => setSelected("pinned")} className={`butn-noti ${selected === "pinned" ? 'butn-noti-focused' : ''}`}>
                                    Pinned
                                </button>
                            </> :
                            <button onClick={() => setSelected("pat")} className={`butn-noti ${selected === "pat" ? 'butn-noti-focused' : ''}`}>
                                {props.from === "pat" ? props.name : "patient"}
                            </button>
                        }
                    </div>
                    {(selected === "all" || selected === 'pinned') && <div style={{ margin: "2px" }}>
                        <input name="resident"
                            placeholder="Search name or ID"
                            value={searchTerm}
                            style={{ fontWeight: "bolder" }}
                            onChange={handleSearchChange} className='enquiryinput' />
                    </div>}
                    <div className="cfa-scroll" >
                        {(requests.length > 0 && searchTerm === '') ?
                            requests
                                .filter(item => selected === "all" || (selected === "pinned" && item.is_pinned))
                                .map(item => (
                                    <div style={{ backgroundColor: "rgba(255, 251, 237, 1)" }} className="callaction_lists" key={item.id}>
                                        <div className="col-10" style={{ paddingLeft: "2%" }}>
                                            <h6 style={{ textTransform: "capitalize" }}><b>{item.cal_for_name}</b> - <span style={{ color: "red" }}>{item.cal_for_reason}</span></h6>
                                            <h6 style={{ textTransform: "capitalize" }}><b>{item.patient_name}</b></h6>
                                            <span>{moment(item.created_at).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? "Today" : moment(item.created_at).format('DD-MM-YYYY')}&nbsp;&nbsp;@{moment(item.created_at).format('HH:mm:ss')}</span>
                                        </div>
                                        <div className="col-2" style={{ padding: "1%" }}>
                                            <svg style={{ float: "right" }} onClick={() => { handleUpdate(item.id, item.is_pinned === true ? "unpin" : 'pin') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path fill={item.is_pinned === true ? "rgba(51, 195, 0, 1)" : "none"} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 4.5l-4 4L7 10l-1.5 1.5l7 7L14 17l1.5-4l4-4M9 15l-4.5 4.5M14.5 4L20 9.5" />
                                            </svg><br /><br />
                                            <svg style={{ float: "right" }} onClick={() => { handleUpdate(item.id, "read", item.cal_for_name, item.patient, item.cal_for_name === 'discharge-request' ? item.discharge_department : item.pat_type, item.mobile_number); }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path fill={item.is_read === true ? "gray" : "rgba(60, 104, 158, 1)"} d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 16H5V7h14v12zm-5.5-6c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5zM12 9c-2.73 0-5.06 1.66-6 4c.94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4zm0 6.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z" />
                                            </svg>
                                        </div>
                                    </div>
                                )) : (searchedRequests.length > 0 && searchTerm !== '') ?
                                searchedRequests
                                    .filter(item => selected === "all" || (selected === "pinned" && item.is_pinned))
                                    .map(item => (
                                        <div style={{ backgroundColor: "rgba(255, 251, 237, 1)" }} className="callaction_lists" key={item.id}>
                                            <div className="col-10" style={{ paddingLeft: "2%" }}>
                                                <h6 style={{ textTransform: "capitalize" }}><b>{item.cal_for_name}</b> - <span style={{ color: "red" }}>{item.cal_for_reason}</span></h6>
                                                <h6 style={{ textTransform: "capitalize" }}><b>{item.patient_name}</b></h6>
                                                <span>{moment(item.created_at).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') ? "Today" : moment(item.created_at).format('DD-MM-YYYY')}&nbsp;&nbsp;@{moment(item.created_at).format('HH:mm:ss')}</span>
                                            </div>
                                            <div className="col-2" style={{ padding: "1%" }}>
                                                <svg style={{ float: "right" }} onClick={() => { handleUpdate(item.id, item.is_pinned === true ? "unpin" : 'pin') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path fill={item.is_pinned === true ? "rgba(51, 195, 0, 1)" : "none"} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 4.5l-4 4L7 10l-1.5 1.5l7 7L14 17l1.5-4l4-4M9 15l-4.5 4.5M14.5 4L20 9.5" />
                                                </svg><br /><br />
                                                <svg style={{ float: "right" }} onClick={() => { handleUpdate(item.id, "read", item.cal_for_name, item.patient, item.cal_for_name === 'discharge-request' ? item.discharge_department : item.pat_type, item.mobile_number); }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path fill={item.is_read === true ? "gray" : "rgba(60, 104, 158, 1)"} d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 16H5V7h14v12zm-5.5-6c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5zM12 9c-2.73 0-5.06 1.66-6 4c.94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4zm0 6.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z" />
                                                </svg>
                                            </div>
                                        </div>
                                    )) :
                                <div className="" style={{ marginTop: "10%", color: "red" }}>No alerts!!</div>
                        }
                    </div>
                </Modal.Body>

            </Modal >
        </div >
    )
}

export default CallFor
