import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Redirect } from "react-router";
//import "./pagination.css";
//import './userlist.css'
import Admitted from "./Admitted";
import "./admission.css";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Link as Scroll } from "react-scroll";
import AddComments from "../Hospital/AddComments";
import ZoomableImage from "../BDO/Admission/ZoomableImage";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import AdmissionView from "../Hospital/Admission/AdmissionView";
//import HospDocNavbar from '../BackoffcieNav/BackofficeNavBar';
let lastPageCalled = 1
let penlastPageCalled = 1
let admlastPageCalled = 1
let verifiedlastPageCalled = 1
let notverifiedlastPageCalled = 1
function AdmissionChairman() {
  const [count, setCount] = useState({
    request: 0,
    pending: 0,
    admitted: 0,
    verified: 0,
    notverified: 0
  })
  const [pv_enquiry_number, setpv_enquiry_number] = useState("");
  const [selected, setSelected] = useState("admitted")
  const [approvedListDup, setapprovedListDup] = useState([]);
  const [verifiedList, setVerifiedList] = useState([]);
  const [notverifiedList, setNotverifiedList] = useState([]);
  const [admissionView, setAdmissionView] = useState(false);
  const [admissionList, setAdmissionList] = useState([])
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false)
  const [admLoading, setAdmLoading] = useState(false);
  const [admPage, setAdmPage] = useState(1);
  const [admHasNextPage, setAdmHasNextPage] = useState(false)
  const [penLoading, setPenLoading] = useState(false);
  const [penPage, setPenPage] = useState(1);
  const [penHasNextPage, setPenHasNextPage] = useState(false)
  const [verifiedPage, setVerifiedPage] = useState(1);
  const [verifiedLoading, setVerifiedLoading] = useState(false);
  const [verifiedHasNextPage, setVerifiedHasNextPage] = useState(false)
  const [notverifiedPage, setNotverifiedPage] = useState(1);
  const [notverifiedLoading, setNotverifiedLoading] = useState(false);
  const [notverifiedHasNextPage, setNotverifiedHasNextPage] = useState(false)
  const [consentView, setConsentView] = useState(false);
  const [remarkData, setRemarkData] = useState('')
  const [requestView, setRequestView] = useState(true);
  const [approveView, setApproveView] = useState(false);
  const [weaknessView, setWeaknessView] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [qualityView, setQualityView] = useState(false);
  const [relativeView, setRelativeView] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [approveComment, setApproveComment] = useState(false);
  const [approveCommentShow, setApproveCommentShow] = useState(false);
  const [rejectaddComments, setRejectaddComments] = useState(false);
  const [refererView1, setRefererView1] = useState(false);
  const [enquiryRejectComment, setEnquiryRejectComment] = useState(false);
  const [enquiryApproveShow, setEnquiryApproveshow] = useState(false);
  const [enquiryApprovalComment, setEnquiryApprovalComment] = useState(false);
  const [addShow2, setAddShow2] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectEnquiryShow, setRejectEnquiryShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [refresh, setRefresh] = useState(false);
  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [itemList, setItemList] = useState({});
  const [user, setUser] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemId3, setItemId3] = useState("");
  const [status, setStatus] = useState("");
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [recommenderView, setRecommenderView] = useState(false);
  const [itemId1, setItemId1] = useState("");
  const [viewData1, setViewData1] = useState([]);
  const [approvedList, setapprovedList] = useState([]);
  const [refresh3, setRefresh3] = useState(false);
  const [searchStarted, setSearchStarted] = useState(false)
  const [viewType, setViewType] = useState("")
  const alert = useAlert();
  let navigate = useNavigate();
  const [refresh4, setRefresh4] = useState(false);
  const [viewAd, setViewAd] = useState(false)
  const login_data = sessionStorage.getItem("logindatas");
  const userData = JSON.parse(login_data);
  const isOfficialAccess = userData.is_official_access;
  let lastScrollTop = 0;
  let lastScrollLeft = 0;
  const handleClick1 = (e) => {
    setSelected("admitted")
    e.preventDefault();
    setRequestView(true);
    setApproveView(false);
    setAdmissionView(false)
    if (searchQuery === "") {
      if (pendingList.length === 0) {
        fetchData(1)
      } else if (hasNextPage) {
        fetchData(page)
      }
    }
  };
  const handleClickApproved = (e) => {
    e.preventDefault();
    setSelected("admitted")
    setRequestView(false);
    setAdmissionView(false)
    setApproveView(true);
    if (searchQuery === "") {
      if (approvedList.length === 0) {
        approveCase(1)
      } else if (admHasNextPage) {
        approveCase(admPage)
      }
    }
  };
  const handleClickAdmission = (e) => {
    e.preventDefault();
    setRequestView(false);
    setApproveView(false);
    setAdmissionView(true)
    if (searchQuery === "") {
      if (admissionList.length === 0) {
        admissionPending(1)
      } else if (penHasNextPage) {
        admissionPending(penPage)
      }
    }
  };
  const handleClickVerified = (e) => {
    e.preventDefault();
    setRequestView(false);
    setApproveView(true);
    setAdmissionView(false)
    setSearchQuery("")
    if (verifiedList.length === 0) {
      admissionVerified(1)
    } else if (verifiedHasNextPage) {
      admissionVerified(verifiedPage)
    }
  };
  const handleClickNotverified = (e) => {
    e.preventDefault();
    setRequestView(false);
    setApproveView(true);
    setAdmissionView(false)
    setSearchQuery("")
    if (notverifiedList.length === 0) {
      admissionNotverified(1)
    } else if (notverifiedHasNextPage) {
      admissionNotverified(notverifiedPage)
    }
  };
  const handleQualityView = (e) => {
    e.preventDefault();
    setQualityView(true)
    setView1(false)

  }

  // useEffect(() => {

  //   let data = JSON.parse(JSON.stringify(approvedListDup))
  //   if (selected === "admitted") {
  //     setapprovedList(approvedListDup)
  //   }
  //   else if (selected === "verified") {
  //     const filteredData = data.filter(x => {
  //       return x.admission_verified === true

  //     })
  //     setapprovedList(filteredData)
  //   }
  //   else if (selected === "notverified") {
  //     const filteredData = data.filter(x => {
  //       return x.admission_verified === false

  //     })
  //     setapprovedList(filteredData)
  //   }

  // }, [selected]);
  const formatFirstName = (name) => {
    return name && name !== "" && name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const handleWeaknessView = (e) => {
    e.preventDefault();
    setWeaknessView(true)
    setView1(false)

  }
  const handleRelativeView = (e) => {
    e.preventDefault();
    setRelativeView(true)
    setView1(false)

  }

  const handleButtonClick1 = () => {
    setView(true)
    setRefererView1(false);

  }
  const handleButtonClick = () => {
    if (viewType === "primary1") {
      setView(true);
    } else {

      setView1(true);
    }

    setRelativeView(false);
    setQualityView(false);
    setWeaknessView(false);
    setRecommenderView(false);
    setRecommenderView(false);
    // setRefererView1(false);
    setConsentView(false)
    setViewType("")

  }

  const handleSearchChange = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);
  };

  const handleComments = (data) => {


    setRemarkData(data)
  }


  const handleContinue = (e) => {
    e.preventDefault();

    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      let dataToSend = {}; // Define the object here

      if (itemId) {
        dataToSend = {
          pv_enquiry_number: itemId,
          pe_status: "approved",
          manager_recommend: remarkData

        };
      } else if (itemId1) {
        dataToSend = {
          pv_enquiry_number: itemId1, // Corrected this from itemId to itemId1
          enquiry_status: "approved",
          mgr_recommend_enquiry: remarkData
        };
      }
      axios
        .put(`${APIURL}/api/v1/service-provider/enquiry-status/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            onAction()
            setApproveCommentShow(false)
            setEnquiryApprovalComment(false)
            setRemarkData('')
            alert.success(res.data.message);

          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => { });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const AddPopUp2 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body style={{ width: "95%", minHeight: "400px", paddingRight: "5%" }}>




          <div className="flex-row" >



            <img
              src={viewData1[0].consent_form}
              alt="consent Report"
              style={{ maxWidth: "100%", height: "auto" }}
            />




          </div>



        </Modal.Body>

      </Modal>
    )
  }

  const ApprovePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{ color: "green" }}>Do you want to give admission to {formatFirstName(itemList.first_name)}? </h4>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setApproveShow(false);
              setApproveComment(true);
              //handleContinue();

            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EnquiryApprovePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{ color: "green" }}>Do you want to approve enquiry form of {formatFirstName(itemList.first_name)} </h4>
          <h6 id="confirm-reject" style={{ color: "green" }}>Approval will be move to chairman confirmation  </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setEnquiryApproveshow(false);
              setEnquiryApprovalComment(true)
              //handleContinue();

            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const RejectPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{ color: "red" }}>Do you want to reject {formatFirstName(itemList.first_name)}?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setRejectShow(false);
              setRejectaddComments(true)
              // handleRejectContinue();

            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RejectEnquiryPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{ color: "red" }}>Do you want to reject the enquiry form of {formatFirstName(itemList.first_name)}?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setRejectEnquiryShow(false);
              setEnquiryRejectComment(true);
              // handleRejectContinue();

            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  const handleRejectClick = (x) => {


    setRejectShow(true);
    setItemList(x)






  }
  const QualityPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header >
          <h4 style={{ marginLeft: '40%' }}>Qualities</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F"}} >
          {enquiryData[0].qualities ? (
            <div style={{textAlign:'left'}}>

              {enquiryData[0].qualities.split(',').map((detail, index) => (
                <div key={index}>
                  {index + 1}. {formatFirstName(detail.trim().replace(/_/g, ' '))}
                </div>
              ))}

            </div>) : (<div>No qualities available</div>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }
  const RelativePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header  >
          <h4 style={{ marginLeft: "32%" }}>Relatives Details</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F", textAlign: "center" }}>
          <div>
            {enquiryData[0].relatives && enquiryData[0].relatives !== null && enquiryData[0].relatives !== "[]" && enquiryData[0].relatives !== undefined ? (
              <div style={{ textAlign: 'left' }}>
                {JSON.parse(enquiryData[0].relatives).map((relative, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {relative.name && formatFirstName(relative.name)}<br />
                    <strong>Age:</strong> {relative.age}<br />
                    <strong>Relation:</strong> {relative.relation && formatFirstName(relative.relation)}<br />
                    <strong>Job:</strong> {relative.job && formatFirstName(relative.job)}<br />
                    <strong>Phone:</strong> {relative.phone !== "91" ? relative.phone : ""}<br />
                    **********************************************
                  </div>
                ))}
              </div>
            ) : (
              <div>No relatives data available</div>
            )}

          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }
  const handleAdmissionClick = (id) => {
    setpv_enquiry_number(id);
    setViewAd(true)
  }

  const AdmissionPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b> Admission Details</b>
          </h4>
          <AdmissionView id={pv_enquiry_number} />
        </Modal.Body>
      </Modal>
    )
  }
  const handleRejectContinue = (data) => {


    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      let dataToSend = {}; // Define the object here


      dataToSend = {
        pv_enquiry_number: itemList.pv_enquiry_number, // Corrected this from itemId to itemId1
        chairman_status: "rejected",
        chairman_comment: data

      }
      axios
        .post(`${APIURL}/api/v1/service-provider/user-admission/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert.success(res.data.message);
            setRejectaddComments(false);
            setEnquiryRejectComment(false);
            //setRemarkData('')
            onAction()
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };
  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{ textAlign: "center" }}>
            <ZoomableImage src={viewType === "consent" ? enquiryData[0].consent_form : viewType === "primary" ? enquiryData[0].pe_report : viewType === "primary1" ? viewData[0].pe_report : enquiryData[0].enquiry_report} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleZoomOut}>Zoom Out</Button>
        <Button variant="secondary" onClick={handleZoomIn}>Zoom In</Button> */}
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const handleEnvelopClick = (id, status) => {

    setItemId(id);
    setItemId1("");
    setStatus(status)
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setViewData(res.data);
          setView(true);
        } else {
          alert.error(res.data.message);
        }
      });


  };

  const WeaknessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header >
          <h4 style={{ marginLeft: '40%' }}>Weakness</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F" }} >
          {enquiryData[0].weakness ? (
            <div style={{ textAlign: 'left' }}>
              {enquiryData[0].weakness.split(',').map((detail, index) => (
                 <div key={index}>
                 {index + 1}. {formatFirstName(detail.trim().replace(/_/g, ' '))}
               </div>
              ))}

            </div>) : (<div>No weakness available</div>)}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const handleEnquiryClick = (id, status) => {

    setItemId1(id);
    setItemId("");
    setEnquiryStatus(status)

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEnquiryData(res.data);
          setView1(true);

        } else {
          alert.error(res.data.message);
        }
      });

  };

  useEffect(() => {
    fetchData(1);
  }, [refresh]);

  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && !loading && hasNextPage && isVerticalScroll && (page !== lastPageCalled)) {
      fetchData(page);
      lastPageCalled = page
    }
  }
  useEffect(() => {
    const scrollContainer = document.getElementById('pendinglist');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading, page, hasNextPage]);

  const handlePenScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && !penLoading && penHasNextPage && isVerticalScroll && (penPage !== penlastPageCalled)) {
      admissionPending(penPage);
      penlastPageCalled = penPage
    } else {
    }
  };


  useEffect(() => {
    const scrollContainer = document.getElementById('approvedlist');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handlePenScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handlePenScroll);
      };
    }
  }, [penLoading, penPage, penHasNextPage]);

  const handleAdmScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && !admLoading && admHasNextPage && isVerticalScroll && (admPage !== admlastPageCalled)) {
      approveCase(admPage);
      admlastPageCalled = admPage
    } else {
    }
  };


  useEffect(() => {
    const scrollContainer = document.getElementById('admittedlist');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleAdmScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleAdmScroll);
      };
    }
  }, [admLoading, admPage, admHasNextPage]);

  const handleVerifiedScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && !verifiedLoading && verifiedHasNextPage && isVerticalScroll && (verifiedPage !== verifiedlastPageCalled)) {
      admissionVerified(verifiedPage);
      verifiedlastPageCalled = verifiedPage
    }
  }
  useEffect(() => {
    const scrollContainer = document.getElementById('verifiedlist');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleVerifiedScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleVerifiedScroll);
      };
    }
  }, [verifiedLoading, verifiedPage, verifiedHasNextPage]);

  const handleNotverifiedScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && !notverifiedLoading && notverifiedHasNextPage && isVerticalScroll && (notverifiedPage !== notverifiedlastPageCalled)) {
      admissionNotverified(notverifiedPage);
      notverifiedlastPageCalled = notverifiedPage
    }
  }
  useEffect(() => {
    const scrollContainer = document.getElementById('notverifiedlist');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleNotverifiedScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleNotverifiedScroll);
      };
    }
  }, [notverifiedLoading, notverifiedPage, notverifiedHasNextPage]);

  const fetchData = async (currentPage) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setLoading(true)
    try {
      const response = await axios.get(`${APIURL}/api/v1/service-provider/enquiry-status/?page=${currentPage}`, {
        headers: {
          Authorization: "Token " + str,
        },
      });

      if (response.status === 200) {
        const pageData = response.data.message
        setPendingList((prevPendingList) => [...prevPendingList, ...pageData]);
        setPage(currentPage + 1)
        setHasNextPage(response.data.has_next)
        setCount(prevCount => ({
          ...prevCount,
          request: response.data.total_records
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false)
  };

  const approveCase = async (currentPage) => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setAdmLoading(true)
    try {
      const response = await axios.get(`${APIURL}/api/v1/service-provider/admission-verification?page=${currentPage}&per_page=10`, {
        headers: {
          Authorization: "Token " + str,
        },
      });

      if (response.data.status === "success") {
        const pageData = response.data.message
        setapprovedList((prevApprovedList) => [...prevApprovedList, ...pageData]);
        setapprovedListDup((prevApprovedListDup) => [...prevApprovedListDup, ...pageData]);
        setAdmPage(currentPage + 1)
        setAdmHasNextPage(response.data.has_next)
        setCount(prevCount => ({
          ...prevCount,
          admitted: response.data.total_records
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setAdmLoading(false)
  };

  const admissionPending = async (currentPage) => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setPenLoading(true)
    try {
      const response = await axios.get(`${APIURL}/api/v1/service-provider/user-admission/?page=${currentPage}&per_page=10`, {
        headers: {
          Authorization: "Token " + str,
        },
      });

      if (response.data.status === "success") {
        const pageData = response.data.results
        setAdmissionList((prevAdmissionList) => [...prevAdmissionList, ...pageData]);
        setPenPage(currentPage + 1)
        setPenHasNextPage(response.data.has_next)
        setCount(prevCount => ({
          ...prevCount,
          pending: response.data.total_records
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setPenLoading(false)
  };

  const admissionVerified = async (currentPage) => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setVerifiedLoading(true)
    try {
      const response = await axios.get(`${APIURL}/api/v1/service-provider/admission-verification/?admission_verified=True&page=${currentPage}`, {
        headers: {
          Authorization: "Token " + str,
        },
      });

      if (response.data.status === "success") {
        const pageData = response.data.message
        setVerifiedList((prevVerifiedList) => [...prevVerifiedList, ...pageData]);
        setVerifiedPage(currentPage + 1)
        setVerifiedHasNextPage(response.data.has_next)
        setCount(prevCount => ({
          ...prevCount,
          verified: response.data.total_records
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setVerifiedLoading(false)
  };

  const admissionNotverified = async (currentPage) => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setNotverifiedLoading(true)
    try {
      const response = await axios.get(`${APIURL}/api/v1/service-provider/admission-verification/?admission_verified=False&page=${currentPage}`, {
        headers: {
          Authorization: "Token " + str,
        },
      });

      if (response.data.status === "success") {
        const pageData = response.data.message
        setNotverifiedList((prevNotverifiedList) => [...prevNotverifiedList, ...pageData]);
        setNotverifiedPage(currentPage + 1)
        setNotverifiedHasNextPage(response.data.has_next)
        setCount(prevCount => ({
          ...prevCount,
          notverified: response.data.total_records
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setNotverifiedLoading(false)
  };

  const onAction = () => {
    lastPageCalled = 1
    penlastPageCalled = 1
    admlastPageCalled = 1
    verifiedlastPageCalled = 1
    notverifiedlastPageCalled = 1
    setSearchQuery('')
    setRequestView(true)
    setSelected("admitted")
    setApproveView(false)
    setAdmissionView(false)
    setPendingList([])
    setapprovedList([])
    setVerifiedList([])
    setNotverifiedList([])
    setAdmissionList([])
    setapprovedListDup([])
    setRefresh(!refresh)
  }

  const ApproveCommentPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"

        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>

            <AddComments value={remarkData} onChange={setRemarkData} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleContinue(e)} >Add</Button>
          <Button variant="primary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const onSuccess = (data) => {



    setRemarkData(data)
    setApproveComment(false)
    handleAdmissionFinal(data)
  }

  const ApproveCommentAdmission = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"

        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>

            <AddComments value={remarkData} onSuccess={onSuccess} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={(e)=>handleContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button> */}


        </Modal.Footer>
      </Modal>
    );
  }


  const EnquiryApprovalPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"

        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>

            <AddComments value={remarkData} onChange={setRemarkData} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleContinue(e)} >Add</Button>
          <Button variant="primary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const RecommenderPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header  >
          <h4 style={{ marginLeft: "32%" }}>Recommender Details</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F", textAlign: "center" }}>
          <div>
            {enquiryData[0].govt_member && enquiryData[0].recomgovt_membermend !== null && enquiryData[0].govt_member !== "[]" && enquiryData[0].govt_member !== undefined ? (
              <div style={{ textAlign: 'left' }}>
                {JSON.parse(enquiryData[0].govt_member).map((relative, index) => (
                  <div key={index}>
                    <strong>Recommender:</strong><span style={{ textTransform: "uppercase" }}> {relative.referer.replace(/_/g, ' ')}</span><br />
                    <strong>Recommender Name:</strong> {relative.name && formatFirstName(relative.name)}<br />

                    <strong>Recommender Phone:</strong> {relative.phone !== '91' ? relative.phone : ""}<br />


                    **********************************************
                  </div>
                ))}
              </div>
            ) : (
              <div>No recommeder data available</div>
            )}

          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }

  const onReject = (data) => {


    // setRemarkData(data)
    setEnquiryRejectComment(false);
    handleRejectContinue(data)
  }
  const RejectCommentPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"

        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>

            <AddComments value={remarkData} onSuccess={onReject} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={(e)=>handleRejectContinue(e)} >Add</Button>
          <Button variant="secondary" onClick={props.onHide}>Close</Button> */}


        </Modal.Footer>
      </Modal>
    );
  }

  const EnquiryRejectPopUp = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"

        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>

            <AddComments value={remarkData} onChange={setRemarkData} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleRejectContinue(e)} >Add</Button>
          <Button variant="primary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }


  useEffect(() => {
    if (searchStarted) {
      if (searchQuery !== '') {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
          .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?search=${searchQuery}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              let data = res.data

              const filteredData1 = data.filter(x => {
                return x.pe_status === "approved" && (x.enquiry_status === "approved") && (x.chairman_status === "pending")
              })
              const filteredData2 = data.filter(x => {
                return x.user !== null && x.admission_status === "approved"
              })
              const filteredData3 = data.filter(x => {
                return x.user !== null && x.admission_status === "pending"
              })

              setPendingList(filteredData1)
              setapprovedList(filteredData2)
              setAdmissionList(filteredData3)

              if (filteredData1.length > 0) {

                setRequestView(true)
                setApproveView(false)
                setAdmissionView(false)

              }

              else if (filteredData3.length > 0) {

                setRequestView(false)
                setApproveView(false)
                setAdmissionView(true)
              }
              else {

                setRequestView(false)
                setApproveView(true)
                setAdmissionView(false)
              }


            }
          });
      }
    }


  }, [searchQuery]
  )


  const handleAdmission = (item) => {
    setItemList(item)
    setItemId3(item.pv_enquiry_number)
    setApproveShow(true)
    //setApproveComment(true)
  }

  const handleAdmissionFinal = (data) => {


    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      const dataToSend = {
        pv_enquiry_number: itemId3,
        chairman_status: "approved",
        chairman_comment: data,
      }



      axios
        .post(`${APIURL}/api/v1/service-provider/user-admission/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {

            alert.success(res.data.message)
            onAction()
            setApproveComment(false)
            //setRegisterId(res.data.patient_id)

          } else {
            alert.error(res.data.message)


          }
        })
        .catch((err) => {

        });
    }
  }

  const RefererPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header  >
          <h4 style={{ marginLeft: "32%" }}>Referrer Details</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "left"
          }}
        >
          <div>
            {viewData[0].referer &&
              viewData[0].referer !== null &&
              viewData[0].referer !== undefined ? (
              <div style={{ textAlign: 'left' }}>

                {
                  viewData[0].referer.map((relative, index) => (
                    relative.name && relative.name !== "null" &&
                    <div key={index}>
                      <strong>Referrer Name:</strong> {relative.name !== "null" ? formatFirstName(relative.name) : ""}
                      <br />
                      <strong>Referrer Phone:</strong> {relative.contact !== "91" ? relative.contact : ""}
                      <br />
                      <div style={{ padding: "2%", backgroundColor: "#E4DD9F", borderRadius: "5px" }}>
                        {relative.comments && relative.comments.split(/\r?\n/).map((segment, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {segment}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ))}

              </div>
            ) : (
              <div style={{ padding: "5px", backgroundColor: "#E4DD9F" }}>No referrer data available</div>
            )}

            <div>{viewData[0].referer &&
              viewData[0].referer !== null &&
              viewData[0].referer !== undefined && ((viewData[0].referer.length === 1 && !viewData[0].referer[0].name) ||
                (viewData[0].referer.length === 1 && viewData[0].referer[0].name === "null") || (viewData[0].referer.length === 2 && !viewData[0].referer[0].name && !viewData[0].referer[1].name) || (viewData[0].referer.length === 2 && viewData[0].referer[0].name === "null" && viewData[0].referer[1].name === "null")) ? <div>No referrer data available</div> : ""}</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick1()}>
            Ok
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }

  const handleRefererView1 = (e) => {
    e.preventDefault();
    setRefererView1(true)
    //setView1(false)
    setView(false)

  }
  const ViewPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b>Primary Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>

              <ListGroup
                style={{ textAlign: "left" }}
                className="list-group-flush"
              >
                <ListGroupItem>
                  <b>Enquiry Number </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].pv_enquiry_number}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>PE Date </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].pe_date !== null && (viewData[0].pe_date).slice(0, 10)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(viewData[0].first_name)} {viewData[0].last_name && formatFirstName(viewData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}> {formatFirstName(viewData[0].gender)}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {viewData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>{viewData[0].location && formatFirstName(viewData[0].location)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].province && formatFirstName(viewData[0].province)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].city && formatFirstName(viewData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].state && viewData[0].state !== "null" ? formatFirstName(viewData[0].state) : ""}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].country}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>{viewData[0].pincode}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{viewData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>
                    {viewData[0].idcard_no}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Manager Recommendation</b>
                  <span style={{ float: "right" }}>
                    {viewData[0].manager_recommend && formatFirstName(viewData[0].manager_recommend)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Referrer </b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => handleRefererView1(e)}> View</button>
                  </span>
                </ListGroupItem>
                {viewData[0].pe_report !== null && <ListGroupItem>
                  <b>Primary Form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("primary1"); setView(false) }}> View</button>
                  </span>
                </ListGroupItem>}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>

      </Modal>
    );
  };

  const handleRecommenderView = (e) => {
    e.preventDefault();
    setRecommenderView(true)
    setView1(false)

  }

  const handleConsentView = () => {
    if (viewType === "primary1") {
      setView(false);
    } else {

      setView1(false);
    }
    setConsentView(true);
  }
  const EnquiryFormPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b> Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>
              <ListGroup
                style={{ textAlign: "left" }}
                className="list-group-flush"
              >
                <ListGroupItem>
                  <b>Enquiry Date </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].enquiry_date !== null && (enquiryData[0].enquiry_date).slice(0, 10)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(enquiryData[0].first_name)} {enquiryData[0].last_name && formatFirstName(enquiryData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}> {formatFirstName(enquiryData[0].gender)}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {enquiryData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>{enquiryData[0].location && formatFirstName(enquiryData[0].location)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].province && formatFirstName(enquiryData[0].province)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].city && formatFirstName(enquiryData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].state}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].country}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].pincode}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{enquiryData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_no}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Manager Recommendation </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].mgr_recommend_enquiry && formatFirstName(enquiryData[0].mgr_recommend_enquiry)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Approved Trust User </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].approving_chairman_name && enquiryData[0].approving_chairman_name}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Trust Decision </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].chairman_comment}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Field Findings </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].field_report && formatFirstName(enquiryData[0].field_report)}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Manager's Recommendation </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].manager_recommend}
                  </span>
                </ListGroupItem> */}

                <ListGroupItem>
                  <b>Primary Enquiry Status </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].pe_status && formatFirstName(enquiryData[0].pe_status)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Parent Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].parent_name && formatFirstName(enquiryData[0].parent_name)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Marital Status</b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].marital_status === true ? "Yes" : enquiryData[0].marital_status === false ? "No" : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Is Parent Alive </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_aliveparent === true ? "Yes" : enquiryData[0].is_aliveparent === false ? "No" : ""}
                  </span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Post Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].post_care
                      ? formatFirstName(enquiryData[0].post_care)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Home </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_home === true ? "Yes" : enquiryData[0].is_home === false ? "No" : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Property </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_property
                      ? formatFirstName(enquiryData[0].own_property)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Land </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_land
                      ? formatFirstName(enquiryData[0].own_land)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Ration Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_rationcard === true ? "Yes" : enquiryData[0].own_rationcard === false ? "No" : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Income </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_income
                      ? formatFirstName(enquiryData[0].own_income)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].bystander_name && formatFirstName(enquiryData[0].bystander_name)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Contact </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].bystander_contact !== "undefined" ? enquiryData[0].bystander_contact : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Qualities </b>
                  <span style={{ float: "right" }}>
                    {/* {enquiryData[0].qualities} */}
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => handleQualityView(e)}> View</button>
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Weakness </b>
                  <span style={{ float: "right" }}>
                    {/* {enquiryData[0].qualities} */}
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => handleWeaknessView(e)}> View</button>
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Relatives</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => handleRelativeView(e)}> View</button>
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Recommendations</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => handleRecommenderView(e)}> View</button>
                  </span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Physical Support </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].physical_support === true ? "Yes" : enquiryData[0].physical_support === false ? "No" : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Police Case Any </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].police_case === true ? "Yes" : enquiryData[0].police_case === false ? "No" : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Undergoing Treatment </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].undergoing_treatment && formatFirstName(enquiryData[0].undergoing_treatment)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Treatment Place </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].treatment_place && formatFirstName(enquiryData[0].treatment_place)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].care_details && formatFirstName(enquiryData[0].care_details)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Auto Biography </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].autobiography && formatFirstName(enquiryData[0].autobiography)}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Investigator </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].investigator}
                  </span>
                </ListGroupItem> */}
                <ListGroupItem>
                  <b>Consent form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("consent") }}> View</button>
                  </span>
                </ListGroupItem>

                {enquiryData[0].enquiry_report !== null && <ListGroupItem>
                  <b>Enquiry Form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("enquiry") }}> View</button>
                  </span>
                </ListGroupItem>}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>

      </Modal>
    );
  };

  return (
    <>
      <div className="facilityouter container" style={{ marginTop: "1%", maxWidth: "100%" }}>
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <h4
              style={{
                color: "#245693",
                marginTop: "1.5em",
                fontFamily: "inter",
                fontWeight: "700",
                marginLeft: "2.5%",
              }}
            >
              Admissions
            </h4>
            <div className="leftsidewindow" style={{ maxWidth: "100%", padding: "10px" }}>
              <div className="searchboxindside" style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  style={{
                    border: "0",
                    outline: "none",
                    width: "100%",
                    paddingLeft: "10px"
                  }}
                  value={searchQuery}
                  onChange={(e) => { if (e.target.value === "") { onAction() } setSearchStarted(true); setSearchQuery(e.target.value); if (e.target.value === "") { setSearchStarted(false) } }}
                  placeholder="Name/PV Id"
                />{" "}&nbsp;&nbsp;
                <i class="fa fa-search" style={{ paddingRight: "10px" }} aria-hidden="true"></i>
              </div>
              <h6
                style={{
                  color: "#2D3748",
                  fontFamily: "Helvetica",
                  fontWeight: "700",
                  marginLeft: "1emx",
                  marginTop: "5px",
                  fontSize: ".75em",
                }}
              >

              </h6>

              <button
                className="approverequset"
                onClick={(e) => handleClick1(e)}
                style={{
                  backgroundColor: requestView ? "#6D4482" : "#F8F8F8",
                  color: requestView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"
                }}
              >
                {" "}
                <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style={{ color: requestView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Requests
                {searchQuery !== "" && <span style={{ float: "right", width: "15%" }}>|&nbsp;{pendingList.length > 0 ? pendingList.length : "NIL"}</span>}
              </button>
              <button
                className="rejectedrequests"
                onClick={(e) => handleClickAdmission(e)}
                style={{
                  backgroundColor: admissionView ? "#6D4482" : "#F8F8F8",
                  color: admissionView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"

                }}
              >
                {" "}
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{ color: admissionView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Admission Pending
                {searchQuery !== "" && <span style={{ float: "right", width: "15%" }}>|&nbsp;{admissionList.length > 0 ? admissionList.length : "NIL"}</span>}
              </button>

              <button
                className="approverequset"
                onClick={(e) => handleClickApproved(e)}
                style={{
                  backgroundColor: approveView ? "#6D4482" : "#F8F8F8",
                  color: approveView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"
                }}
              >
                {" "}
                <i
                  class="fa fa-file"
                  aria-hidden="true"
                  style={{ color: approveView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Admitted
                {searchQuery !== "" && <span style={{ float: "right", width: "15%" }}>|&nbsp;{approvedList.length > 0 ? approvedList.length : "NIL"}</span>}
              </button>

            </div>
          </div>
          <div className="col-lg-10 col-md-10">
            <h5
              style={{
                color: "red",
                marginLeft: "10%",
                marginTop: "10px",
                fontFamily: "inter",
                fontSize: "18px",
                fontStyle: "italic",
                fontWeight: "600",
                textAlign: "right",
              }}
            >




              {requestView && pendingList.length > 0 && (
                <div>
                  {count.request} request is pending
                </div>
              )}


              {requestView && pendingList.length === 0 && (
                <div>No pending requests </div>
              )}
              {admissionView && admissionList.length > 0 && (
                <div>
                  {`${count.pending} ${admissionList.length === 1 ? "Requet " : "Requests "
                    }pending `}
                </div>
              )}
              {approveView && selected === 'admitted' && approvedList.length > 0 && (
                <div>
                  {`${count.admitted} ${approvedList.length === 1 ? "Request" : "Requests"
                    }`}
                </div>
              )}
              {approveView && selected === 'verified' && verifiedList.length > 0 && (
                <div>
                  {`${count.verified} ${verifiedList.length === 1 ? "Verified request" : "Verified requests"
                    } `}
                </div>
              )}
              {approveView && selected === 'notverified' && notverifiedList.length > 0 && (
                <div>
                  {`${count.notverified} ${notverifiedList.length === 1 ? "Non-verified request" : "Non-verified requets"
                    } `}
                </div>
              )}

              {admissionView && admissionList.length === 0 && (
                <div>No admission requests found</div>
              )}

              {approveView && approvedList.length === 0 && (
                <div>No approved requests </div>
              )}
            </h5>

            {requestView ? (
              <>

                <div id="pendinglist" style={{ maxHeight: "800px", overflow: "auto" }}>
                  {pendingList.length > 0 && <div className="row" style={{ display: "flex", marginTop: "3%", width: "62rem", marginLeft: "1%" }}>
                    <h6 className={isOfficialAccess ? "col-3" : "col-4"}></h6>
                    <h6 className={isOfficialAccess ? "col-2" : "col-3"}
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        textAlign: "center"

                      }}
                    >
                      Primary
                    </h6>
                    <h6 className={isOfficialAccess ? "col-2" : "col-3"}
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        textAlign: "center"
                        // marginLeft: "9%",
                      }}
                    >
                      Enquiry
                    </h6>
                    {isOfficialAccess && <><h6 className="col-2"
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        textAlign: "center"
                        //marginLeft: "8%",
                      }}
                    >
                      Admission
                    </h6><h6 className="col-2"
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        textAlign: "center"
                        // marginLeft: "8%",
                      }}
                    >
                        Reject
                      </h6></>}
                  </div>
                  }
                  {pendingList.
                    //             filter((item) => {
                    //   // Replace 'fullName' with the property that contains the patient's full name
                    //   const fullName = `${item.first_name} ${item.last_name}`;
                    //   return fullName.toLowerCase().includes(searchQuery.toLowerCase());
                    // }).

                    map((item) => (
                      <div className="row" onClick={() => setUser(item.pv_enquiry_number)} style={{ display: "flex", width: "62rem" }}>
                        <div
                          className="patientlistview"
                          style={{
                            border: user === item.pv_enquiry_number && "1px solid #6D4482",
                            display: "flex",
                            marginLeft: "2%",
                            marginTop: "1%",
                            marginBottom: "1%"

                          }}
                        >
                          <div className={isOfficialAccess ? "col-3" : "col-4"} style={{}}>
                            <h6
                              style={{
                                color: "black",
                                fontWeight: "700",
                                fontFamily: "inter",
                                marginTop: "10px",
                                marginLeft: "0px",
                                justifyContent: "center",
                                fontSize: "16px",
                                textTransform: "Capitalize",
                              }}
                            >

                              {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span><br />

                            </h6>
                          </div>

                          <div className={isOfficialAccess ? "col-2" : "col-3"} style={{}}>
                            {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                            <div

                              style={{
                                width: "123px",
                                height: "40px",
                                border: "1px solid grey",
                                borderRadius: "5px",
                                margin: "auto",
                                marginTop: "18px",
                                cursor: "pointer",
                              }}

                            >
                              {item.pe_status === "pending" ? (
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    {itemId !== item.pv_enquiry_number ?
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="
                          #4F87CB" class="bi bi-envelope-fill" viewBox="0 0 16 16">

                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                        <path fill="none" d="M0 0h36v36H0z" />
                                        <circle
                                          cx="13"
                                          cy="3"
                                          r="3"

                                          fill="red"
                                          class="clr-i-outline--badged clr-i-outline-path-2--badged clr-i-badge"
                                        />
                                        <path fill="none" d="M0 0h36v36H0z" />
                                      </svg> : <i class="fa fa-envelope-open" aria-hidden="true" style={{ fontSize: "25px", color: "#245693" }}></i>}</div> <div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "15px", marginTop: "4px", cursor: "pointer" }} onClick={() => handleEnvelopClick(item.pv_enquiry_number, item.pe_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693" /><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>

                              ) : item.pe_status === "approved" ? (

                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="25"
                                      fill="#5ECB5B"
                                      class="bi bi-envelope-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg> </div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "15px", marginTop: "4px" }} onClick={() => handleEnvelopClick(item.pv_enquiry_number, item.pe_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693" /><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="red"
                                  class="bi bi-envelope-x-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                </svg>
                              )}
                            </div>
                          </div>
                          <div className={isOfficialAccess ? "col-2" : "col-3"}>
                            <div

                              style={{
                                width: "123px",
                                height: "40px",
                                border: "1px solid grey",
                                borderRadius: "5px",
                                margin: "auto",
                                marginTop: "18px",
                                cursor: "pointer",
                              }}

                            >
                              {item.enquiry_status === null ? (

                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    <i
                                      class="fa fa-envelope"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "23px",
                                        fontWeight: "900",
                                        color: "rgb(158, 158, 158)",
                                        width: "25px",
                                        height: "30px",
                                        marginLeft: "-10px",
                                        marginTop: "0px",
                                      }}
                                      disabled={

                                        item.enquiry_status === null
                                      }
                                    // onClick={() => navigate("/enquiry")}
                                    ></i> </div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "10px", marginTop: "4px" }}   ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="grey" /><path fill="grey" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>
                              ) : item.enquiry_status === "approved" ? (
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="25"
                                      fill="#5ECB5B"
                                      class="bi bi-envelope-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg> </div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "15px", marginTop: "4px", cursor: "pointer" }} onClick={() => handleEnquiryClick(item.pv_enquiry_number, item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693" /><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>
                              ) : item.enquiry_status === "rejected" ? (
                                <div style={{ marginLeft: "-1%" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="25"
                                    fill="red"
                                    class="bi bi-envelope-x-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                  </svg>
                                </div>
                              ) : <div style={{ display: "flex" }}>
                                <div style={{ marginTop: "5px", marginLeft: "5px" }}>{itemId1 !== item.pv_enquiry_number ? <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="
                          #4F87CB" class="bi bi-envelope-fill" viewBox="0 0 16 16">

                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                  <path fill="none" d="M0 0h36v36H0z" />
                                  <circle
                                    cx="13"
                                    cy="3"
                                    r="3"

                                    fill="red"
                                    class="clr-i-outline--badged clr-i-outline-path-2--badged clr-i-badge"
                                  />
                                  <path fill="none" d="M0 0h36v36H0z" />
                                </svg> : <i class="fa fa-envelope-open" aria-hidden="true" style={{ fontSize: "25px", color: "#245693" }}></i>}</div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "15px", marginTop: "4px", cursor: "pointer" }} onClick={() => handleEnquiryClick(item.pv_enquiry_number, item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693" /><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>}
                            </div>
                          </div>


                          {isOfficialAccess && <><div className="col-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {/* Buttons for approval */}
                            <button
                              style={{
                                width: "100px",
                                height: "40px",
                                borderRadius: "5px",
                                color: "white",
                                backgroundColor: "rgb(97, 198, 95)",
                                cursor: "pointer",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                              }}
                              onClick={() => handleAdmission(item)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="21"
                                viewBox="0 0 22 21"
                                fill="none"
                              >
                                <path
                                  d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                                  fill="white"
                                />
                              </svg>{" "}
                            </button>
                          </div>
                            <div className="col-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <button
                                onClick={(e) => handleRejectClick(item)}
                                style={{
                                  borderRadius: "5px",
                                  width: "100px",
                                  height: "40px",
                                  background: "#C6725F",
                                  color: "white",
                                  cursor: "pointer",
                                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                                }}
                              >
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                                    fill="white"
                                  />
                                </svg>{" "}
                                &nbsp;{" "}
                              </button>{" "}
                            </div>
                          </>}
                        </div>
                      </div>
                    ))}
                </div>

              </>
            )

              : admissionView ?
                <div id="approvedlist" style={{ maxHeight: "800px", overflow: "auto" }}>
                  {admissionView && <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
                    <h6
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        marginLeft: "41%",
                      }}
                    >
                      Primary
                    </h6>
                    <h6
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        marginLeft: "18%",
                      }}
                    >
                      Enquiry
                    </h6>
                    <h6
                      style={{
                        fontFamily: "inter",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#6D4482",
                        marginLeft: "15%",
                      }}
                    >

                    </h6>
                  </div>
                  }
                  {admissionView &&
                    admissionList.length > 0 && admissionList


                      .map((item) => (
                        <div
                          className="row" onClick={() => setUser(item.pv_enquiry_number)}
                          style={{ display: "flex", width: "62rem" }}
                        >
                          <div
                            className="patientlistview"
                            style={{
                              border: user === item.pv_enquiry_number && "1px solid #6D4482",
                              display: "flex",
                              marginBottom: "1%",
                              marginLeft: "2%"

                            }}
                          >
                            <div className="col-4">
                              <h6
                                style={{
                                  color: "black",
                                  fontWeight: "700",
                                  fontFamily: "inter",
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                  justifyContent: "center",
                                  textTransform: "capitalize",
                                  fontSize: "16px",

                                }}
                              >
                                {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span><br />
                              </h6>
                            </div>
                            {/* <div className="col-2"><i class="fa fa-file" aria-hidden="true" style={{marginTop:'30px',cursor:"pointer"}}
//onClick={()=>handleViewDetails(item.pv_enquiry_number)}
>

</i>
</div> */}
                            <div className="col-3" >
                              {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                              <div
                                style={{
                                  width: "123px",
                                  height: "40px",
                                  border: "1px solid grey",

                                  borderRadius: "5px",
                                  marginTop: "20px",
                                  marginLeft: "20px",
                                }}
                              >

                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="28"
                                      fill="#5ECB5B"
                                      class="bi bi-envelope-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg>{" "}
                                  </div>
                                  <div
                                    style={{
                                      width: "1px",
                                      backgroundColor: "grey",
                                      marginLeft: "25px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {" "}
                                  </div>{" "}
                                  <div
                                    style={{
                                      marginLeft: "15px",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleEnvelopClick(item.pv_enquiry_number)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="28"
                                      viewBox="0 0 32 32"
                                    >
                                      <circle
                                        cx="16"
                                        cy="16"
                                        r="4"
                                        fill="#245693"
                                      />
                                      <path
                                        fill="#245693"
                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                      />
                                    </svg>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div className="col-3" style={{}}>
                              <div
                                style={{
                                  flexDirection: "column",
                                  width: "123px",
                                  height: "40px",
                                  border: "1px solid #CDCDCD",
                                  borderRadius: "5px",
                                  marginTop: "23px",
                                  marginLeft: "20px",
                                  textAlign: "center",
                                  display: "flex",

                                  alignItems: "center",
                                }}
                              >

                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "4%" }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="25"
                                      fill="#5ECB5B"
                                      class="bi bi-envelope-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg>
                                  </div>{" "}
                                  <div
                                    style={{
                                      width: "1px",
                                      backgroundColor: "grey",
                                      marginLeft: "25px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {" "}
                                  </div>{" "}
                                  <div
                                    style={{
                                      marginLeft: "15px",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleEnquiryClick(item.pv_enquiry_number)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="28"
                                      viewBox="0 0 32 32"
                                    >
                                      <circle
                                        cx="16"
                                        cy="16"
                                        r="4"
                                        fill="#245693"
                                      />
                                      <path
                                        fill="#245693"
                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                      />
                                    </svg>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="col-2">
                              {item.admission_status === null ? (

                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    <i
                                      class="fa fa-envelope"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "23px",
                                        fontWeight: "900",
                                        color: "rgb(158, 158, 158)",
                                        width: "25px",
                                        height: "30px",
                                        marginLeft: "-10px",
                                        marginTop: "0px",
                                      }}
                                      disabled={

                                        item.enquiry_status === null
                                      }
                                    // onClick={() => navigate("/enquiry")}
                                    ></i> </div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "10px", marginTop: "4px" }}   ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="grey" /><path fill="grey" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>
                              ) : item.enquiry_status === "pending" ? (
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="25"
                                      fill="#5ECB5B"
                                      class="bi bi-envelope-check-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg> </div><div style={{ width: "1px", backgroundColor: "grey", marginLeft: "25px", marginTop: '5px' }}>  </div>  <div style={{ marginLeft: "15px", marginTop: "4px", cursor: "pointer" }} onClick={() => handleEnquiryClick(item.pv_enquiry_number, item.enquiry_status)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 32 32"><circle cx="16" cy="16" r="4" fill="#245693" /><path fill="#245693" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z" /></svg></div></div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>

                : approveView ?
                  <>
                    <div className="row" >

                      <div className="col-7 row">
                        <div className="col-4">
                          <div onClick={(e) => { setSelected("admitted"); handleClickApproved(e) }} style={{ cursor: "pointer", padding: "1.5%", color: selected === "admitted" ? "#5ECB5B" : "gray", borderColor: selected === "admitted" ? "#5ECB5B" : "gray" }} className="icons-right-not ">
                            <AiTwotoneCheckCircle /> Admitted
                          </div>
                        </div>
                        <div className="col-4">
                          <div onClick={(e) => { setSelected("verified"); handleClickVerified(e) }} style={{ cursor: "pointer", padding: "1.5%", color: selected === "verified" ? "#5ECB5B" : "gray", borderColor: selected === "verified" ? "#5ECB5B" : "gray" }} className="icons-right-not ">
                            <AiTwotoneCheckCircle /> Verified
                          </div>
                        </div>
                        <div className="col-4">
                          <div onClick={(e) => { setSelected("notverified"); handleClickNotverified(e) }} style={{ cursor: "pointer", padding: "1.5%", color: selected === "notverified" ? "#5ECB5B" : "gray", borderColor: selected === "notverified" ? "#5ECB5B" : "gray" }} className="icons-right-not ">
                            <AiTwotoneCheckCircle /> Not verified
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {selected === "admitted" ? (
                      approvedList.length > 0 ? <div id="admittedlist" style={{ maxHeight: "800px", overflow: "auto" }}> <Admitted approvedList={approvedList} onEnvelopClick={handleEnvelopClick} onEnquiryClick={handleEnquiryClick} onAdmissionClick={handleAdmissionClick} /></div> : ""
                    ) : selected === "verified" ? (
                      verifiedList.length > 0 ? <div id="verifiedlist" style={{ maxHeight: "800px", overflow: "auto" }}> <Admitted approvedList={verifiedList} onEnvelopClick={handleEnvelopClick} onEnquiryClick={handleEnquiryClick} onAdmissionClick={handleAdmissionClick} /></div> : ""
                    ) : selected === "notverified" ? (
                      notverifiedList.length > 0 ? <div id="notverifiedlist" style={{ maxHeight: "800px", overflow: "auto" }}> <Admitted approvedList={notverifiedList} onEnvelopClick={handleEnvelopClick} onEnquiryClick={handleEnquiryClick} onAdmissionClick={handleAdmissionClick} /></div> : ""
                    ) : ("")}
                  </>
                  :
                  ""}
          </div>
        </div>
        {view ? (
          <ViewPopUp
            show={view}
            onHide={() => {
              setView(false);
            }}
          />
        ) : ""}

        {view1 ? (
          <EnquiryFormPopUp
            show={view1}
            onHide={() => {
              setView1(false);
            }}
          />
        ) : null}
        {approveShow ? (
          <ApprovePopUp
            show={approveShow}
            onHide={() => {
              setApproveShow(false);
            }}
          />
        ) : null}


        {approveCommentShow ? (
          <ApproveCommentPopUp
            show={approveCommentShow}
            onHide={() => {
              setApproveCommentShow(false);
            }}
          />
        ) : null}
        {approveComment ? (
          <ApproveCommentAdmission
            show={approveComment}
            onHide={() => {
              setApproveComment(false);
            }}
          />
        ) : null}

        {
          refererView1 ?
            <RefererPopUp1
              show={refererView1}
              onHide={() => setRefererView1(false)}

            /> : null
        }

        {enquiryApprovalComment ? (
          <EnquiryApprovalPopUp
            show={enquiryApprovalComment}
            onHide={() => {
              setEnquiryApprovalComment(false);
            }}
          />
        ) : null}
        {enquiryApproveShow ? (
          <EnquiryApprovePopUp
            show={enquiryApproveShow}
            onHide={() => {
              setEnquiryApproveshow(false);
            }}
          />
        ) : null}

        {
          recommenderView ?
            <RecommenderPopUp
              show={recommenderView}
              onHide={() => setRecommenderView(false)}

            /> : null
        }
        {rejectShow ? (
          <RejectPopUp
            show={rejectShow}
            onHide={() => {
              setRejectShow(false);
            }}
          />
        ) : null}

        {rejectaddComments ? (
          <RejectCommentPopUp
            show={rejectaddComments}
            onHide={() => {
              setRejectaddComments(false);
            }}
          />
        ) : null}

        {enquiryRejectComment ? (
          <EnquiryRejectPopUp
            show={enquiryRejectComment}
            onHide={() => {
              setEnquiryRejectComment(false);
            }}
          />
        ) : null}

        {rejectEnquiryShow ? (
          <RejectEnquiryPopUp
            show={rejectEnquiryShow}
            onHide={() => {
              setRejectEnquiryShow(false);
            }}
          />
        ) : null}
        {
          qualityView ?
            <QualityPopUp
              show={qualityView}
              onHide={() => setQualityView(false)}

            /> : null
        }

        {
          consentView ?
            <ConsentPopUp
              show={consentView}
              onHide={() => setConsentView(false)}

            /> : null
        }

        {
          relativeView ?
            <RelativePopUp
              show={relativeView}
              onHide={() => setRelativeView(false)}

            /> : null
        }

        {
          addShow2 ?
            <AddPopUp2
              show={addShow2}
              onHide={() => setAddShow2(false)}

            /> : null
        }
        {viewAd ? (
          <AdmissionPopUp
            show={viewAd}
            onHide={() => {
              setViewAd(false);
            }}
          />
        ) : null}
        {
          weaknessView ?
            <WeaknessPopUp
              show={weaknessView}
              onHide={() => setWeaknessView(false)}

            /> : null
        }
      </div>
    </>
  );
}
export default AdmissionChairman;
