
import axios from "axios";
import React, { useState, useEffect, useRef } from 'react'
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import "./deathreport.css";
import PersonalInventory from '../Inventory/PersonalInventory'
import PatientSearch from '../../common/PatientSearch';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DatePicker } from "rsuite";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import FormView from "../../Manager/DeathCertificate/FormView";
import SimpleBar from 'simplebar-react';
import ZoomableImage from "../Admission/ZoomableImage";
import Payments from "../Payments/Payments";
function ApprovePending({ person, onRefresh }) {
    const alert = useAlert()
    const [formShow, setFormShow] = useState(false)
    const [invShow, setInvShow] = useState(false)
    const [patientDetails, setPatientDetails] = useState({})
    const [govtFiles, setGovtFiles] = useState({ id: "", file: "" })
    const [info, setInfo] = useState("")
    const [show, setShow] = useState(false);
    const [uploadedBy, setUploadedBy] = useState("")
    const [liabilityShow, setLiabilityShow] = useState(false)
    const user = sessionStorage.getItem('usertype')
    const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormView patient={patientDetails} />

                </Modal.Body>
            </Modal>
        );
    };
    const InfoPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body style={{ padding: "7%" }}>
                    <div className="form-button" style={{ textAlign: "center" }}>
                        <ZoomableImage src={info} />
                        {uploadedBy !== "" && uploadedBy !== null && <p>Uploaded by:<span style={{ textTransform: "capitalize" }}>{uploadedBy}</span></p>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const InvPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <PersonalInventory patient={patientDetails} from={"deathrep"} />
                </Modal.Body>
            </Modal>
        );
    };
    const reader = new FileReader();
    const handleFile = async (e) => {

        const key = e.target.name;
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);

        if (!file) {
            setGovtFiles(currentstate => ({ ...currentstate, file: "" }))
            alert.error("Please Select a File");

            return;
        }

        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            setGovtFiles(currentstate => ({ ...currentstate, file: "" }))
            alert.error("Select a valid image or PDF file");

            return;
        }

        if (file.size > 10e6) {
            setGovtFiles(currentstate => ({ ...currentstate, file: "" }))
            alert.error("Select a file smaller than 10MB");

            return;
        }

        const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;

        if (file.type.startsWith('image/')) {

            setGovtFiles(currentstate => ({ ...currentstate, file: file }))
        } if (file.type === 'application/pdf') {
            // Handle PDF file

            setGovtFiles(currentstate => ({ ...currentstate, file: file }))

        }
    };

    const submit = (status, id) => {

        const tokenString = sessionStorage.getItem('usertoken');
        const formData = new FormData();
        formData.append('government_file', govtFiles.file);
        let str = tokenString.replace(/["]+/g, '')



        axios.put(`${APIURL}/api/v1/service-provider/death-status/?de_id=${govtFiles.id}`, formData, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success("successfully Updated")
                    onRefresh()
                } else {

                }
            })

    }

    const onSuccess = () => {
        setLiabilityShow(false);
        onRefresh()
    }

    const ViewLiabilityPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}

            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <h6 style={{ marginLeft: "2%", textTransform: "capitalize", width: "100%" }}><b>{patientDetails.patient_name}</b>
                        <button
                            style={{ backgroundColor: "white", float: "right" }}
                            variant="secondary"
                            onClick={() => {
                                setLiabilityShow(false);
                            }}
                        >
                            <i class="fa fa-times fa-lg" style={{ color: "black", float: "right" }} aria-hidden="true"></i>
                        </button></h6>
                </Modal.Header>
                <Modal.Body style={{ justifyContent: "left", minHeight: "400px" }}>
                    <Payments patient={patientDetails} onSuccess={() => onSuccess()} from="discharge" />
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <div>
            {person.length > 0 && <div className="row">
                <label className='death-text-style-here col-4 ' ></label>
                <label className='death-text-style-here col-2' >Form for the Deceased</label>
                <label className={`death-text-style-here ${user === "hospital_manager" ? 'col-2' : 'col-1'}`} style={{ padding: 0 }}>Inventories</label>
                {user!=='hospital_manager' && <label className='death-text-style-here col-1' style={{ padding: 0 }}>Liabilities</label>}
                <label className='death-text-style-here col-2' >Government Form</label>
                <label className='death-text-style-here col-2' >Submit</label>
            </div>}
            <SimpleBar style={{ maxHeight: '800px', paddingLeft: "2%", paddingRight: "2%" }}>
                {person.length > 0 ? person.map((item) => {
                    return (
                        <div className="deathreq_lists row">
                            <div className="col-4" style={{ padding: "1%", fontSize: "18px" }}>{item.patient}&nbsp;&nbsp;&nbsp;{item.patient_name}</div>
                            <div className="col-2" style={{ paddingLeft: "2%" }} >
                                <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", padding: "1%", width: "70%" }} className="row">
                                    <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)" }} className="col-6">
                                        {item.status === "pending" ?

                                            <span class="fa-stack fa-lg" style={{}}  >
                                                <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color: "#4F87CB" }} ></i>
                                            </span>
                                            : <svg style={{ marginTop: "18%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"><path fill="rgba(94, 203, 91, 1)" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z" /></svg>}
                                    </div>
                                    <div style={{ paddingTop: "7%" }} className="col-6">
                                        <i onClick={() => { setFormShow(true); setPatientDetails(item) }}
                                            class="fa fa-eye" style={{ fontSize: "23px", marginLeft: "5%", color: "rgba(36, 86, 147, 1)" }} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className={`${user === "hospital_manager" ? 'col-2' : 'col-1'}`} onClick={() => { setInvShow(true); setPatientDetails(item) }} style={{ display: "flex" }}><svg style={{ margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path fill="#2ea098" d="M17.03 6C18.11 6 19 6.88 19 8v11c0 1.13-.89 2-1.97 2c0 .58-.47 1-1.03 1c-.5 0-1-.42-1-1H9c0 .58-.5 1-1 1c-.56 0-1.03-.42-1.03-1C5.89 21 5 20.13 5 19V8c0-1.12.89-2 1.97-2H9V3c0-.58.46-1 1-1h4c.54 0 1 .42 1 1v3h2.03M13.5 6V3.5h-3V6h3M8 9v9h1.5V9H8m6.5 0v9H16V9h-1.5m-3.25 0v9h1.5V9h-1.5Z" /></svg></div>
                            {user!=='hospital_manager' && <div className="col-1" onClick={() => { if (item.payment_status) { setLiabilityShow(true); setPatientDetails(item) } }} style={{ cursor: item.payment_status ? "pointer" : "not-allowed", display: "flex" }}>
                                <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                    <path fill={item.payment_status ? "rgb(75, 156, 223)" : "silver"} d="M12 12.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7M10.5 16a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0" />
                                    <path fill={item.payment_status ? "rgb(75, 156, 223)" : "silver"} d="M17.526 5.116L14.347.659L2.658 9.997L2.01 9.99V10H1.5v12h21V10h-.962l-1.914-5.599zM19.425 10H9.397l7.469-2.546l1.522-.487zM15.55 5.79L7.84 8.418l6.106-4.878zM3.5 18.169v-4.34A3 3 0 0 0 5.33 12h13.34a3 3 0 0 0 1.83 1.83v4.34A3 3 0 0 0 18.67 20H5.332A3.01 3.01 0 0 0 3.5 18.169" />
                                </svg>
                            </div>}
                            <div className="col-2" style={{ paddingTop: "", paddingLeft: "5%" }}> {(item.government_file !== null) || (govtFiles.file !== "" && govtFiles.id === item.id) ? <>
                                <i onClick={() => {
                                    typeof (item.government_file) !== "object" && setShow(true)
                                    setInfo(item.government_file);
                                    setUploadedBy(item.file_upload_by)

                                }} style={{ color: typeof (item.government_file) === "object" ? "gray" : "rgba(36, 86, 147, 1)", fontSize: "24px", marginTop: "8%" }} class="fa fa-eye" aria-hidden="true"></i></> :
                                <> <label onClick={() => setGovtFiles(currentstate => ({ ...currentstate, file: "", id: item.id }))} style={{ backgroundColor: "white", border: "none", cursor: "pointer", paddingTop: "8%" }}><i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true"
                                    style={{
                                        fontSize: "25px",
                                        fontWeight: "900",
                                        color: "#852ea0",
                                        width: "44px",
                                        height: "20px",
                                        marginTop: "5%",

                                        cursor: item.status === "approved" ? "pointer" : "not-allowed", // Change cursor style
                                    }}


                                > </i><input type="file" style={{ display: "none" }} disabled={item.status !== "approved"}
                                    onChange={handleFile} />
                                </label></>}</div>
                            <div style={{ paddingTop: "0.5%" }} className="col-2">
                                <button

                                    onClick={() => govtFiles.id === item.id && govtFiles.file !== "" ? submit() : ""}
                                    style={{ width: "80px", height: "35px", borderRadius: "5px", backgroundColor: govtFiles.id === item.id && govtFiles.file !== "" ? "green" : "gray" }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="21"
                                        viewBox="0 0 22 21"
                                        fill="none"
                                    >

                                        <path

                                            d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"

                                            fill="rgba(255, 255, 255, 1)"

                                        />

                                    </svg></button>
                                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                {/* {props.status&&<svg  onClick={() =>{setPatientDetails(item);exportReport() } }xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="rgba(97, 198, 95, 1)" d="M18 17.5v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75v-1.251l-1.75.001A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18Zm-2.25-4h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75Zm.002-9H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75Z"/></svg>} */}

                            </div>
                        </div>
                    )
                }) : <div className="" style={{ marginTop: "10%", color: "red" }}>No Reports!!</div>}
            </SimpleBar>
            {formShow ? (
                <FormPopUp
                    show={formShow}
                    onHide={() => {
                        setFormShow(false);
                    }}
                />
            ) : null}
            {invShow ? (
                <InvPopUp
                    show={invShow}
                    onHide={() => {
                        setInvShow(false);
                    }}
                />
            ) : null}
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
            {liabilityShow && (
                <ViewLiabilityPopup
                    show={liabilityShow}
                    onHide={() => setLiabilityShow(false)}
                />
            )}
        </div>
    )
}

export default ApprovePending;