import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
// import "./pagination.css";
// import './userlist.css';
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from '../../../Global';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import "simplebar-react/dist/simplebar.min.css";
import bed from "../../../assets/images/icons/bed.png";
import ward from "../../../assets/images/icons/ward.png";
// import AssignPatient from './AssignPatient';
import { CgCloseR } from "react-icons/cg";
import Payments from '../Payments/Payments';
import DischargeForm from './DischargeForm';
import PersonalInventory from '../Inventory/PersonalInventory';
let lastPageCalled = 1
let lastPageCalled1 = 1
function DischargePatients() {
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas")) || null;
  const role = login_datas?.role;
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNext1, setHasNext1] = useState(true);
  const [page1, setPage1] = useState(1);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [patient, setPatient] = useState()
  const [liabilityShow, setLiabilityShow] = useState(false)
  const [shelter, setShelter] = useState([])
  const [dialysis, setDialysis] = useState([])
  const [physio, setPhysio] = useState([])
  const [palliative, setPalliative] = useState([])
  const alert = useAlert()
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [dept_id, setdept_id] = useState()
  const [general, setGeneral] = useState([])
  const [action, setAction] = useState("")
  const location = useLocation()
  const [idSearch, setIdSearch] = useState(location?.state?.department_id || '')
  const navigate = useNavigate()
  let lastScrollTop = 0;
  let lastScrollLeft = 0;

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    axios

      .get(`${APIURL}/api/v1/service-provider/master-departments/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDepartmentOptions(res.data)

        } else {
          alert.error(res.data.message);
        }
      });
  }, []);

  useEffect(() => {
    const isNavigated = sessionStorage.getItem('dischargeSearch') === 'true';
    if (isNavigated && location.state?.pat_id) {
      setIdSearch(location.state?.pat_id)
      onCfaSearch(location.state?.pat_id, location.state?.department_id)
      sessionStorage.removeItem('dischargeSearch');
      navigate(location.pathname, { replace: true }); // Clear state
    } else if (location.state?.pat_id) {
      navigate(location.pathname, { replace: true }); // Clear state on reload
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (departmentOptions?.length > 0 && role) {
      if (role === "nurse") {
        setSelected('Dialysis')
      } else if (role === "alliedprofessional") {
        setSelected('Physiotherapy')
      } else {
        setSelected('Shelter Home')
      }
    }
  }, [departmentOptions, role])

  useEffect(() => {
    const selectedDepartment = departmentOptions.length > 0 && departmentOptions.find(dept => dept.department_name === selected);
    setdept_id(selectedDepartment.id)
  }, [selected])

  useEffect(() => {
    setPatientList([]);
    setSelectedPatient('');
    lastPageCalled = 1
    lastPageCalled1 = 1
    setPage(1)
    setPage1(1)
    if (selected && search === '' && dept_id && idSearch === '') {
      handleLoad(1);
    }
  }, [dept_id, refresh]);

  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

    if (isBottom && hasNext && isVerticalScroll && (page === lastPageCalled + 1)) {
      handleLoad(page);
      lastPageCalled = page;
    }
  };

  useEffect(() => {
    const scrollContainer = document.getElementById('patients');
    if (scrollContainer && search === '') {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, hasNext]);

  const handleLoad = async (currentPage) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    axios.get(`${APIURL}/api/v1/service-provider/admitted-patient/?department_id=${dept_id}&per_page=10&page=${currentPage}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          let Data = []
          Data = res.data.patients
          if (currentPage === 1) {

            setPatientList(Data);
          } else {
            setPatientList([...patientList, ...Data]);
          }
          setHasNext(res.data.has_next)
          setPage(currentPage + 1)
        } else {
          // Handle error case
        }
      })
      .catch(err => {
        // Handle error case
      });
  };

  useEffect(() => {

    if (search !== '') {
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '')
      const value = search
      let url = `${APIURL}/api/v1/service-provider/admitted-patient/?search=${value}&per_page=10&page=${1}&per_page=30`
      axios
        .get(url, {
          headers: {
            "Authorization": 'Token ' + str
          }
        })
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.patients;

            const filteredData1 = data.filter(patient => { return patient.department_name === 'Shelter Home' });
            const filteredData2 = data.filter(patient => { return patient.department_name === 'Dialysis' });
            const filteredData3 = data.filter(patient => { return patient.department_name === 'Physiotherapy' });
            const filteredData4 = data.filter(patient => { return patient.department_name === 'Palliative' });
            const filteredData5 = data.filter(patient => { return patient.department_name === 'Hospital' });
            console.log(filteredData1, "filteredData1")
            setShelter(filteredData1)
            setDialysis(filteredData2)
            setPhysio(filteredData3)
            setPalliative(filteredData4)
            setGeneral(filteredData5)
            if (filteredData3.length > 0 && role !== 'nurse') {
              setSelected('Physiotherapy')
            } else if (filteredData5.length > 0 && role !== 'alliedprofessional') {
              setSelected('Hospital')
            } else if (filteredData4.length > 0 && role !== 'alliedprofessional') {
              setSelected('Palliative')
            } else if (filteredData2.length > 0 && role !== 'alliedprofessional') {
              setSelected('Dialysis')
            } else if (filteredData1.length > 0 && role === 'bdo') {
              setSelected('Shelter Home')
            } else {
              setSelected(role == 'bdo' ? 'Shelter Home' : role === 'nurse' ? 'Dialysis' : 'Physiotherapy')
            }
          }
        }).catch((error) => {
          // Handle error case
        });
    } else {
      setPhysio([])
      setDialysis([])
      setShelter([])
      setPalliative([])
      setGeneral([])
      setRefresh(!refresh)
    }
  }, [search, refreshSearch]);

  const onCfaSearch = (val, id) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    axios.get(`${APIURL}/api/v1/service-provider/admitted-patient/?department_id=${id}&patient_id=${val}&per_page=10&page=${1}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          setPatientList(res.data.patients);
        } else {
          // Handle error case
        }
      })
      .catch(err => {
        // Handle error case
      });
  }

  const handlePatientSearch = (e) => {
    setIdSearch('')
    const val = e.target.value;
    setSearch(val);
  };



  const ViewLiabilityPopup = (props) => {
    return (
      <Modal
        {...props}
        size={action === "view_inventory" ? "xl" : "lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <h6 style={{ marginLeft: "2%", textTransform: "capitalize", width: "100%" }}>{action === "view_liability" && <b>{patient.patient_name}</b>}
            <button
              style={{ backgroundColor: "white", float: "right" }}
              variant="secondary"
              onClick={() => {
                setLiabilityShow(false);
              }}
            >
              <i class="fa fa-times fa-lg" style={{ color: "black", float: "right" }} aria-hidden="true"></i>
            </button></h6>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: "left", minHeight: "400px" }}>
          {action === "view_liability" ? <Payments patient={patient} onSuccess={() => { setLiabilityShow(false); search !== "" ? setRefreshSearch(!refreshSearch) : setRefresh(!refresh) }} from="discharge" />
            : action === "view_form" ? <DischargeForm patient={patient} selected={selected} department={dept_id} onSuccess={() => { setLiabilityShow(false); search !== "" ? setRefreshSearch(!refreshSearch) : setRefresh(!refresh) }} />
              : <PersonalInventory patient={patient} from={"discharge"} />}
        </Modal.Body>
      </Modal>
    );
  };

  const renderPatients = (value, index) => {
    return (
      <div className='list-item-new' >
        <div className='row' style={{ paddingTop: "1%", paddingBottom: "1%" }}>
          <div className='col-12 row' >
            <div className="col-4" style={{ alignContent: 'center' }}>
              <button className="dir-button1" style={{ padding: 0, backgroundColor: "white", width: "100%" }} >
                <h6 style={{ letterSpacing: "0px", fontSize: "medium", textTransform: "capitalize", color: "#4b9cdf" }} className="each-list-link2" >&nbsp;{value.patient_name}</h6>
              </button>
              <small style={{ textAlign: "left", textTransform: "capitalize", }}>&nbsp;&nbsp;{value.patient_type}</small>
            </div>
            <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', }} > <p className='each-list-link2' style={{ fontSize: "medium" }}><i className="fa fa-address-card fa-lg" aria-hidden="true" style={{ fontSize: "1rem" }}></i>&nbsp; {value.admission_number} </p></div>
            <div className='col-2' style={{ display: "flex", alignItems: "center", alignContent: 'center', flexWrap: 'wrap' }}>
              {(selected !== 'general' && selected !== 'dialysis') && <p className='each-list-link2' style={{ alignContent: 'center', fontSize: "medium", color: "#4b9cdf", cursor: login_datas.user_type === 'hospital_bdo' ? "pointer" : "default" }}>
                <img
                  src={
                    value.bedspace_type === "ward"
                      ? ward
                      : value.bedspace_type === "room"
                        ? bed
                        : ""
                  }
                  alt="Ward"
                  style={{ width: "25%", backgroundColor: "white", marginTop: "-5px", padding: "1%" }}
                /> {value.bedunit_code}
              </p>}
            </div>
            <div className='col-1' style={{ paddingTop: "1%" }}>
              <svg onClick={() => { setLiabilityShow(true); setAction("view_liability"); setPatient(value) }} style={{ cursor: value.payment_status ? "pointer" : "not-allowed" }} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill={value.payment_status ? "rgb(75, 156, 223)" : "silver"} d="M12 12.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7M10.5 16a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0" /><path fill={value.payment_status ? "rgb(75, 156, 223)" : "silver"} d="M17.526 5.116L14.347.659L2.658 9.997L2.01 9.99V10H1.5v12h21V10h-.962l-1.914-5.599zM19.425 10H9.397l7.469-2.546l1.522-.487zM15.55 5.79L7.84 8.418l6.106-4.878zM3.5 18.169v-4.34A3 3 0 0 0 5.33 12h13.34a3 3 0 0 0 1.83 1.83v4.34A3 3 0 0 0 18.67 20H5.332A3.01 3.01 0 0 0 3.5 18.169" /></svg>
            </div>
            <div className="col-1" onClick={() => { if (value.inventories_status) { setLiabilityShow(true); setAction("view_inventory"); setPatient(value) } }} style={{ display: "flex", cursor: value.inventories_status ? "pointer" : "not-allowed" }}>
              <svg style={{ margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                <path fill={value.inventories_status ? "#2ea098" : "silver"} d="M17.03 6C18.11 6 19 6.88 19 8v11c0 1.13-.89 2-1.97 2c0 .58-.47 1-1.03 1c-.5 0-1-.42-1-1H9c0 .58-.5 1-1 1c-.56 0-1.03-.42-1.03-1C5.89 21 5 20.13 5 19V8c0-1.12.89-2 1.97-2H9V3c0-.58.46-1 1-1h4c.54 0 1 .42 1 1v3h2.03M13.5 6V3.5h-3V6h3M8 9v9h1.5V9H8m6.5 0v9H16V9h-1.5m-3.25 0v9h1.5V9h-1.5Z" />
              </svg></div>
            <div className='col-2' style={{ paddingTop: "1%" }}>
              <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", padding: "1%", width: "70%" }} className="row">
                <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)" }} className="col-6">
                  <svg style={{ marginTop: "18%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"><path fill={value.discharge_status ? "rgba(94, 203, 91, 1)" : "silver"} d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z" /></svg>
                </div>
                <div style={{ paddingTop: "7%" }} className="col-6">
                  <i
                    onClick={() => { if (value.discharge_status) { setLiabilityShow(true); setAction("view_form"); setPatient(value) } }}
                    class="fa fa-eye" style={{ fontSize: "23px", marginLeft: "5%", cursor: value.discharge_status ? "pointer" : "not-allowed", color: value.discharge_status ? "rgba(36, 86, 147, 1)" : "silver" }} aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const departmentTabs = () => {
    const filteredDepartments = departmentOptions.filter((item) => {
      if (role === "nurse") {
        return !["Administration", "Physiotherapy", "Shelter Home"].includes(item.department_name);
      }
      if (role === "alliedprofessional") {
        return item.department_name === "Physiotherapy";
      }
      return item.department_name !== "Administration";
    });

    return (
      <>
        {filteredDepartments.map((item) => (
          <button
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', }}
            key={item.id}
            className={selected === item.department_name ? "selected-button" : "side-button"}
            onClick={() => { setSelected(item.department_name); }}
          >
            {item.department_name === "Hospital" ? "General" : item.department_name}
            <small style={{ position: 'absolute', right: '10px', }}>
              {search !== '' && (
                (item.department_name === "Hospital" && general.length > 0) ? general.length :
                  (item.department_name === "Shelter Home" && shelter.length > 0) ? shelter.length :
                    (item.department_name === "Dialysis" && dialysis.length > 0) ? dialysis.length :
                      (item.department_name === "Palliative" && palliative.length > 0) ? palliative.length :
                        (item.department_name === "Physiotherapy" && physio.length > 0) ? physio.length : ''
              )}
            </small>

          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className='row' style={{ padding: '0px 20px', paddingBottom: "1%" }}>
        <div className='col-lg-2 col-md-2'>
          <label className='contents-head' style={{ margin: 0, fontSize: '22px' }}>IP Departments</label>
          <div className="side-tabs" >
            <div className='sidebox-search-input-container' style={{ marginBottom: '10px' }}>
              <input
                className='sidebox-search-input'
                type='text'
                value={search || idSearch}
                placeholder='Search Patient...'
                onChange={(e) => handlePatientSearch(e)} />
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.5904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#5D5D5D" />
              </svg>
            </div>
            {departmentTabs()}
          </div>
        </div>
        <div id="patients" className='col-10 shrink-out' style={{ overflowY: "auto", overflowX: "hidden", maxHeight: '500px', marginTop: '32px' }}>
          <div className="">
            {search === '' && patientList.length > 0
              ? patientList.map((value, index) => renderPatients(value, index))
              : search !== '' && shelter.length > 0 && selected === 'Shelter Home'
                ? shelter.map((value, index) => renderPatients(value, index))
                : search !== '' && dialysis.length > 0 && selected === 'Dialysis'
                  ? dialysis.map((value, index) => renderPatients(value, index))
                  : search !== '' && physio.length > 0 && selected === 'Physiotherapy'
                    ? physio.map((value, index) => renderPatients(value, index))
                    : search !== '' && palliative.length > 0 && selected === 'Palliative'
                      ? palliative.map((value, index) => renderPatients(value, index))
                      : search !== '' && general.length > 0 && selected === 'Hospital'
                        ? general.map((value, index) => renderPatients(value, index))
                        : <h6 className='nodata'>No Patients Found</h6>
            }
          </div>
        </div>
      </div>
      {liabilityShow ? (
        <ViewLiabilityPopup
          show={liabilityShow}
          onHide={() => {
            setLiabilityShow(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default DischargePatients;
