import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../Global";
function PatientSearch(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [patients, setPatients] = useState([])
    const [display, setDisplay] = useState(false);
    const initialRender = useRef(true)
    const [status, setStatus] = useState("admitted")


    useEffect(() => {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (initialRender.current) {
            initialRender.current = false
        }

        else if (searchTerm !== "") {

            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(/["]+/g, "");
            let url = ""
            if (props.from === "inv" && status === 'deceased') {
                url = `${APIURL}/api/v1/staff/patient-list/?timeZone=${currentTimezone}&deceased=true&per_page=30&page=10&q=${searchTerm}`
            } else if (props.from === "inv" && status === 'discharged') {
                url = `${APIURL}/api/v1/staff/patient-list/?timeZone=${currentTimezone}&discharged=true&per_page=30&page=10&q=${searchTerm}`
            } else {
                url = `${APIURL}/api/v1/staff/patient-list/?timeZone=${currentTimezone}&per_page=30&page=10&q=${searchTerm}&ip=true`
            }

            axios
                .get(url, {
                    headers: { Authorization: "Token " + v2 },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setPatients(res.data.patient)
                    }
                    else {

                    }
                }).catch((error) => {

                })

        }

    }, [searchTerm,])

    const handleSearchChange = (e) => {
        if (e.target.value === "") {
            setDisplay(false)

        } else {
            setDisplay(true)
        }
        setPatients([])
        setSearchTerm(e.target.value)
    }
    return (
        <>

            <div style={{ width: "100%", marginTop: "1%" }} className='flex-row'>
                {props.from === "inv" && <> <div className="enquiryinput" style={{ textAlign: "center", border: "none", marginTop: "1%" }}>
                    {["admitted", "deceased", "discharged"].map((item) => (
                        <label key={item} style={{ margin: "0 20px", fontSize: "17px", color: status === item ? "#2196F3" : "gray" }}>
                            <input
                                type="radio"
                                name="status"
                                value={item}
                                checked={status === item}
                                onChange={() => {
                                    setStatus(item);
                                    setSearchTerm("");
                                    props.onRadioChng();
                                }}
                                style={{ marginRight: "5px" }}
                            />
                            <b>{item.charAt(0).toUpperCase() + item.slice(1)}</b>
                        </label>
                    ))}
                </div>
                </>}

                <input
                    style={{ width: '50%' }}
                    className="form-control"
                    type="text"
                    maxLength="100"
                    placeholder="Search name, ID, Phone number"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <button disabled={searchTerm === ""} className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                </button>

            </div>


            <div className='flex-row' style={{ justifyContent: "center" }}>
                {
                    patients.length > 0 && display ?

                        <div className='suggest-display ' style={{ width: "54%", }}>
                            <button style={{ backgroundColor: "#F8F8F8", marginLeft: "92%" }} variant="secondary" onClick={() => { setDisplay(false) }}>
                                <i class="fa fa-times-circle " style={{ color: "#0d6efd" }} aria-hidden="true"></i>
                            </button>
                            {
                                patients.length > 0 ? patients.map((item) => {
                                    return (
                                        <div style={{ fontWeight: "700" }} onClick={() => {
                                            props.onSuccess({
                                                full_name: item.full_name,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                                mobile_number: item.mobile_number,
                                                id: item.id,
                                                room: item.bedunit_code,
                                                gender: item.gender,
                                                location: item.location,
                                                admission_date: item.admission_date,
                                                deceased: status,
                                                death_status: item.death_status
                                            }); setSearchTerm(""); setDisplay(false);
                                        }} className='suggest-item text-secondary row'>
                                            <div className='col-5'>{item.full_name}</div>
                                            <div className='col-3'>ID:{item.id}</div>
                                            <div className='col-4'>MOB:{item.mobile_number}</div>
                                        </div>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>

                        : null
                }</div>
        </>
    )
}
export default PatientSearch